import { QueryKey, UseQueryOptions, useQuery } from '@tanstack/react-query'

import { CitySchema } from '@Schemas/domain/state'

import contentAPI from '@Services/contentAPI'

type ParamProps = 'cityId' | 'urlKey' | 'geolocation'

type GeolocationProps = {
  lat: string
  lng: string
  resultCount?: string
  radiusInKilometers?: string
}

type ModeProps = {
  [key in ParamProps]?: key extends 'geolocation' ? GeolocationProps : string
}

export const queryKey = (mode: ModeProps) => {
  const [param, value] = Object.entries(mode)[0]
  const key = typeof param !== 'object' ? ['city', value] : ['city']

  return key as QueryKey
}

export const fetchCity = async (mode: ModeProps) => {
  const [param, value] = Object.entries(mode)[0]
  const isGeolocation = param === 'geolocation'

  const basePath = '/v0/states/city/'

  const paths: { [key in ParamProps]: string } = {
    cityId: value as string,
    urlKey: `name/${value}`,
    geolocation: `latlong?lat=${mode?.geolocation?.lat}&lng=${
      mode?.geolocation?.lng
    }&resultCount=${
      mode?.geolocation?.resultCount ?? 5
    }&radiusInKilometers=${mode?.geolocation?.radiusInKilometers ?? 50}`
  }

  const url = basePath + paths[param]
  const response = await contentAPI.get(url)
  const { data } = response

  const cityData = isGeolocation ? data[0] : data
  const parsedData = CitySchema.parse(cityData)

  return parsedData
}

type SuccessResponse = Awaited<ReturnType<typeof fetchCity>>
type ErrorResponse = unknown

type CityQueryOptions = Omit<
  UseQueryOptions<
    SuccessResponse,
    ErrorResponse,
    SuccessResponse,
    ReturnType<typeof queryKey>
  >,
  'queryKey' | 'queryFn'
>

const useCityQuery = (mode: ModeProps, options?: CityQueryOptions) => {
  return useQuery({
    queryKey: queryKey(mode),
    queryFn: () => fetchCity(mode),
    ...options
  })
}

export default useCityQuery
