export const HOME = 'home'
export const MOVIES = 'movie_list'
export const MOVIE = 'movie'
export const THEATERS = 'theater_list'
export const THEATER = 'theater'
export const EVENT = 'event'
export const EVENTS = 'spetacle_list'
export const EVENTS_EVENT_TYPE = 'event_type_spetacle_list'
export const FANSHOP = 'fanshop'
export const PROMOTIONS = 'promotions_list'
export const VIP_ZONE = 'vip_zone'
export const SEARCH_RESULT = 'search_result'
export const NEWS = 'news'
export const ARTICLE = 'article'
export const PRESS_OFFICE = 'partners'
export const CUSTOMER_SERVICE = 'press_release'
export const SCAM_WARNING = 'fake_websites_emails'
export const NOT_FOUND = '404'

export const CUSTOM_PAGE_VIEW_PAGES = [MOVIE, THEATER, ARTICLE]
