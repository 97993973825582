import { usePathname } from 'next/navigation'

import * as pages from '@Shared/constants/page-name'

export const usePageName = () => {
  const path = usePathname()
  const splitedPath = path?.split('/')

  const institutionalPageNames = {
    'assessoria-imprensa': pages.PRESS_OFFICE,
    'ingresso-atende': pages.CUSTOMER_SERVICE
  }

  const pageNameList = {
    filmes: pages.MOVIES,
    filme: pages.MOVIE,
    cinemas: pages.THEATERS,
    cinema: pages.THEATER,
    evento: pages.EVENT,
    fanshop: pages.FANSHOP,
    promocoes: pages.PROMOTIONS,
    'vip-zone': pages.VIP_ZONE,
    busca: pages.SEARCH_RESULT,
    noticias: splitedPath.length > 2 ? pages.ARTICLE : pages.NEWS,
    eventos: splitedPath.length > 2 ? pages.EVENTS_EVENT_TYPE : pages.EVENTS,
    politicas: pages.SCAM_WARNING,
    institucional: institutionalPageNames[splitedPath[2]],
    '404': pages.NOT_FOUND
  }

  const pageName = pageNameList[splitedPath[1]] || pages.HOME

  return pageName
}
