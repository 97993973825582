'use client'

import { useSearchParams } from 'next/navigation'
import { ComponentProps, useEffect, useState } from 'react'

import { cn } from '@Shared/helpers/util'
import useAdvertiseStore from '@Shared/hooks/use-advertise-store/use-advertise-store'
import useCity from '@Shared/hooks/use-city'
import { usePageName } from '@Shared/hooks/use-page-name'
import usePartnership from '@Shared/hooks/use-partnership'

import { Event } from '@Schemas/domain/event'
import {
  SimplifiedTheaterWithSessionsByType,
  TTheater
} from '@Schemas/domain/theater'

type AdBannerProps = ComponentProps<'div'> & {
  movie?: Event
  theater?: TTheater | SimplifiedTheaterWithSessionsByType
}

const UOLAdContainer = ({
  className,
  id,
  movie,
  theater,
  ...props
}: AdBannerProps) => {
  const searchParams = useSearchParams()
  const [uolAdsLoaded] = useAdvertiseStore()
  const [targets, setTargets] = useState<Record<string, string | string[]>>({})

  const { city } = useCity()
  const partnership = usePartnership()

  const pageName = usePageName()
  const partnershipQuery = searchParams?.get('partnership')

  const hasTargets = Boolean(Object.keys(targets).length)

  useEffect(() => {
    if (
      (partnershipQuery && partnership.id !== partnershipQuery) ||
      (pageName === 'theater' && !theater) ||
      (pageName === 'movie' && !movie) ||
      !pageName
    ) {
      return
    }

    setTargets({
      country: 'br',
      uf: city?.uf || '',
      theaterId: theater?.id || '',
      theaterCityId: theater?.cityId || '',
      partnership: partnership?.id || 'home',
      pagename: pageName || '',
      movieId: movie?.id || '',
      genres: movie?.genres || [],
      exhibitorId: theater?.corporationId || '',
      contentRating: movie?.contentRating || '',
      neighborhood: theater?.neighborhood || '',
      city: city?.name || ''
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partnership, pageName, movie, theater])

  useEffect(() => {
    if (!window.googletag) {
      window.googletag = { cmd: [] }
    }
  }, [])

  useEffect(() => {
    if (uolAdsLoaded && hasTargets) {
      window?.uolads?.push({
        id,
        customTargetings: targets
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uolAdsLoaded, targets])

  return hasTargets ? (
    <div
      id={id}
      className={cn(
        'flex h-auto w-full items-center justify-center [&_div]:first:empty:m-0 [&_div]:first:empty:max-h-0 [&_div]:first:empty:p-0',
        className
      )}
      {...props}
    ></div>
  ) : null
}

export default UOLAdContainer
